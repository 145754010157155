@import "./mixins/fonts.scss";

.item {
  align-items: center;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 0;
  border: 1px solid var(--clr-primary);
}

.disabled-page {
  color: #808e9b;
}

.active {
  color: #808e9b;
  background-color: var(--clr-primary);
}

.pagination {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  gap: 0px;
}

.pagination-page {
  font-weight: 700;
}

.previous {
  font-size: 14px;
  padding: 4px 8px;
  width: 80px;
  user-select: none;
  cursor: pointer;
  a {
    @include setFontPrimary(14px, var(--clr-secondary));
  }
}

.next {
  font-size: 14px;
  padding: 4px 8px;
  width: 80px;
  cursor: pointer;
  user-select: none;
  a {
    @include setFontPrimary(14px, var(--clr-secondary));
  }
}
