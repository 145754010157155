/*
1. Variables
2. Reset css
3. Fonts
4. Overrides
*/

/* 1. Variables Start */
:root {
  --clr-primary: #1a59af;
  --clr-secondary: #1d2837;

  --toastify-color-success: #ea8a00;

  --clr-danger: #da1919;
  --border: #d1d1d1;
  --border-dk: #cecece;
  --border-lt: #efefef;

  --bg-primary: linear-gradient(90deg, #e3eeff -0.4%, #dff5ff 100%);
  --bg-secondary: #f4fcff;
  --bg-tertiary: #ffffff;

  --bg-disabled: #a5b0b9;
  --bg-white: #fff;
  --bg-faded: #f6f6f6;
  --bg-primary-gradient: #0093e9
    linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);

  --btn-primary: #e4eeff;

  --text-white: #fff;
  --text-faded: #4c4c4c;
  --text-faded-dk: #a9a9a9;

  --card-primary: #fff;

  --success: rgba(27, 176, 131, 1);
  --info: #cbeaff;
}

/* 1. Variables End */

/* 2. Reset css Start */
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition-timing-function: ease-in;
  font-family: "DroidSerif", sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

body {
  flex: 1;
}

html {
  font-size: 16px;
}

button {
  cursor: pointer;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

// Container
.container {
  max-width: 1180px;
  padding: 0px 0px;
  margin: 0px auto;
}

.container-xl {
  max-width: 1280px;
  padding: 0px 0px;
  margin: 0px auto;
}

.container-xxl {
  max-width: 1380px;
  padding: 0px 0px;
  margin: 0px auto;
}

#inputQuery {
  width: 100%;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  border: 1px solid var(--border);
  height: 64px;
  padding: 0px 14px;
  position: relative;
}
input::placeholder {
  font-family: var(--primary-regular);
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 14px * 1.5;
}

textarea[type="text"] {
  width: 100%;
  padding: 10px 20px;
  margin: 0px 0px 8px 0px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  border: none;
  background: var(--bg-input);
  position: relative;
  border-left: 5px solid var(--clr-secondary);
}

textarea[type="text"]:focus {
  border: none;
  outline: none;
  border-left: 5px solid var(--clr-secondary);
}

textarea:focus,
input:focus {
  outline: none;
}

select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='20' viewBox='0 0 24 24' width='20' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  padding: 8px 12px;
  min-width: 130px;
  font-family: var(--primary-semibold);
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  line-height: normal;
}
select > option {
  background-color: #fff;
  font-family: var(--primary-semibold);
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
}

.faq-drawer {
  padding: 12px 10px;
  background-color: #fff;
  margin-bottom: 18px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid var(--border);
}
.faq-drawer__content-wrapper {
  max-height: 0px;
  overflow: hidden;
  transition: 0.25s ease-in-out;
}
.faq_content {
  font-family: var(--primary-regular);
  font-size: 14px;
  line-height: 18px;
}

.faq-drawer__title {
  cursor: pointer;
  display: block;
  position: relative;
  margin-bottom: 0;
  transition: all 0.25s ease-out;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--secondary-semibold);
}

.faq-drawer__title::after {
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: " ";
  display: inline-block;
  float: right;
  height: 10px;
  position: relative;
  right: 0px;
  transform: rotate(135deg);
  transition: 0.35s ease-in-out;
  vertical-align: top;
  width: 10px;
  color: var(--clr-secondary);
  top: 4px;
}

.faq-drawer__trigger:checked
  + .faq-drawer__title
  + .faq-drawer__content-wrapper {
  max-height: 350px;
  border-top: 2px solid var(--border);
  margin-top: 12px;
  padding-top: 12px;
}

.faq-drawer__trigger:checked + .faq-drawer__title::after {
  transform: rotate(-45deg);
  transition: 0.25s ease-in-out;
}

input[type="checkbox"] {
  display: none;
}

.slider {
  border-radius: 10px;
}
.slider-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  height: 300px;
}

.slide {
  height: 100%;
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: url("../public/images/icons/next.png") no-repeat center center /
    36px;
  width: 58px;
  height: 58px;
  text-indent: -9999px;
  cursor: pointer;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.nextButton {
  right: 0;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  border-radius: 6px;
  padding: 8px 16px;
  object-fit: contain;
}

.slider-content section span {
  color: #000;
  font-family: var(--secondary-bold);
}

.slider-content section span {
  color: #000;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
}
.slider-content .slider-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

#filter-care-type {
  border: 1px solid var(--border);
  width: 100%;
  min-height: 64px;
}

.detect-location {
  font-size: 18px;
  line-height: 20px;
  font-family: var(--secondary-bold);
  cursor: pointer;
}

.common-input {
  width: 100%;
  padding: 10px 18px;
  margin: 0px 0px 8px 0px;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  border: 1px solid var(--border-dk);
  background: var(--bg-input);
  position: relative;
}

@media screen and (min-width: 768px) {
  #inputQuery {
    margin: 0;
    width: 100%;
  }
  select {
    min-width: 180px;
  }
  .slider-wrapper {
    height: 60vh;
  }
}

.common-modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: #fff;
  border-radius: 8px;
}
.common-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}
input[type="file"] {
  display: none;
}
.nextButton {
  svg {
    display: none;
  }
}
.previousButton {
  svg {
    display: none;
  }
}
